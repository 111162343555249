<template>
    <div>
        <v-system-bar color="grey" lights-out height="30" class="pl-5 pr-5">
            <span class="mr-5">{{this.time }}</span>
            <span>{{ this.date_now }}</span>
        </v-system-bar>
        <v-app-bar style="background-color:#0078d4;max-height: 64px;" dark sticky>
            <v-toolbar-title class="">
                <P>ABSENCE PERMIT</P>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-toolbar-title class="pr-6" v-on="on" style="font-size:0.99rem; cursor:pointer">
                        <v-avatar size="45px" item class="mr-1">
                            <v-img :src="avatar" alt="Admin"/>
                        </v-avatar>
                            {{ employeeName ? employeeName : 'Unknown' }}
                        <v-icon class="hidden-sm-and-down">
                            mdi-chevron-down
                        </v-icon>
                    </v-toolbar-title>
                </template>
                <v-list dense>
                    <v-list-item-group
                        color="primary"
                    >
                        <v-list-item>
                            <v-card class="rounded-l" width="100%">
                                <v-card-text class="p-1">
                                    <div class="d-flex">
                                        <v-avatar size="45px" item class="mr-2">
                                            <v-img :src="avatar" alt="Admin"/>
                                        </v-avatar>
                                        <p class="mt-3">
                                            {{ employeeName ? employeeName : 'Unknown' }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-list-item>
                        <v-list-item>
                            <v-btn type="submit" block class="rounded-l border-12 mr-12 pl-12 pr-12 pt-2 pb-2 mt-3" color="primary" @click="logout()">Logout</v-btn>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-app-bar>

        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card elevation-5">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12 col-12">
                                    <span>Welcome, {{ this.employeeName }}</span>
                                </div>
                                <div class="col-lg-12 col-12">
                                    <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                        <template v-slot:top>
                                            <v-toolbar flat color="white">
                                                <!-- <div class="row">
                                                    <div class="col-6">
                                                        <v-text-field class="w-50" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                    </div>
                                                    <div class="col-6 text-right">
                                                        <v-btn class="ma-3" color="green" elevation="2" small rounded @click="showDialogAbsenceRequest()" dark>New<v-icon right dark>mdi-file-plus</v-icon></v-btn>
                                                        <v-btn class="ma-3" color="primary" elevation="2" small rounded @click="populateAbsenceList()">Refresh<v-icon right dark>mdi-autorenew</v-icon></v-btn>
                                                        <v-btn class="ma-3" color="red" elevation="2" small rounded @click="logout()" dark>Logout<v-icon right dark>mdi-logout</v-icon></v-btn>
                                                    </div>
                                                </div> -->
                                                <v-spacer></v-spacer>
                                                <v-btn class="ma-3" color="green" elevation="2" small rounded @click="showDialogAbsenceRequest()" dark>New<v-icon right dark>mdi-file-plus</v-icon></v-btn>
                                                <v-btn class="ma-3" color="primary" elevation="2" small rounded @click="populateAbsenceList()">Refresh<v-icon right dark>mdi-autorenew</v-icon></v-btn>
                                                <!-- <v-btn class="ma-3" color="red" elevation="2" small rounded @click="logout()" dark>Logout<v-icon right dark>mdi-logout</v-icon></v-btn> -->
                                            </v-toolbar>
                                        </template>
                                        <template v-slot:[`item.file_name`]="{ item }">
                                            <v-chip v-if="item.file_name == 'Y'" @click="showImage(item.doc_id)" color="green" small dark>view</v-chip>
                                        </template>
                                        <template v-slot:[`item.stat`]="{ item }">
                                            <v-chip class="ma-2" v-if="item.stat == 'O'" color="blue" dense small dark>OPEN</v-chip>
                                            <v-chip class="ma-2" v-else-if="item.stat == 'C'" color="red" dense small dark>CLOSED</v-chip>
                                            <v-chip class="ma-2" v-if="item.stat == 'O'" color="red" @click="deleteAbsence(item.doc_id)" dense small dark><v-icon>mdi-delete</v-icon></v-chip>
                                        </template>
                                    </v-data-table>
                                </div> 
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog v-model="dialogCreateRequest" max-width="1280px" >
            
            <div class="card elevation-5">
                <div class="card-body">
                    <h5 class="card-title mb-8">New Absence Request </h5>
                    <div class="row">
                        <div class="col-lg-3 col-6">
                            <v-select v-model="addCompanyIDModel" :items="addCompanyIDList" item-value="entity_id" item-text="description" label="Company" disabled dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                        </div>
                        <div class="col-lg-5 col-6">
                            <v-select v-model="addIjinModel" :items="addIjinList" item-value="str1" item-text="str2" label="reason" :loading="addIjinLoading" dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                        </div>
                        <div class="col-lg-2 col-6">
                            <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                </template>
                                <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </div>
                        <div class="col-lg-2 col-6">
                            <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                </template>
                                <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </div>
                        <div class="col-lg-12 col-12">
                            <v-textarea class="pt-5" v-model="addRemarkModel" counter label="Remark" dense></v-textarea>
                        </div>
                        <div class="col-lg-12 col-12">
                            <!-- <input type="file" name="image" id="image" multiple @change="fileChange" class="" accept="image/*" /> -->
                            <v-file-input v-model="fileUpload" label="Picture file" small-chips counter multiple show-size prepend-icon="mdi-image" accept="image/*"></v-file-input>
                        </div>
                        <div class="col-lg-12 col-12 text-right">
                            <v-btn class="mr-3" color="red" elevation="2" small rounded @click="cancelAbsenceRequest()" dark>Cancel</v-btn>
                            <v-btn class="mr-3" color="success" elevation="2" small rounded @click="submitAbsenceRequest()" dark>Submit</v-btn>
                        </div>
                    </div>
                </div>
            </div>
        
        </v-dialog>

        <v-dialog v-model="dialogLogin" persistent max-width="500px" >
        
            <div class="card elevation-5">
                <div class="card-body">
                    <div class="row">
                        <v-tabs-items v-model="tab" touchless>
                            <v-tab-item value="tab-1">

                                <div class="col-lg-12 col-12">
                                    <v-text-field class="mt-5" v-model="usernameModel" :error-messages="usernameErrorMessages" hint="Enter your employee ID" label="Username" dense prepend-icon="mdi-account"></v-text-field>
                                </div>
                                <div class="col-lg-12 col-12 text-right">
                                    <v-btn class="mr-3" color="red" elevation="2" small rounded @click="cancelLogin()" dark>Cancel</v-btn>
                                    <v-btn class="mr-3" color="success" elevation="2" small rounded @click="goToNextTab()" dark>{{ btnNextLabel }}<v-icon>mdi-arrow-right</v-icon></v-btn>
                                </div>

                            </v-tab-item>
                            <v-tab-item value="tab-2">

                                <div class="col-lg-12 col-12">
                                    <v-text-field class="mt-5" v-model="passwordModel" hint="Enter your password" :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass" label="Password" password dense prepend-icon="mdi-lock" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"></v-text-field>
                                </div>
                                <div class="col-lg-12 col-12 text-right">
                                    <v-btn class="mr-3" color="red" elevation="2" small rounded @click="tab = 'tab-1'" dark>Back</v-btn>
                                    <v-btn class="mr-3" color="success" elevation="2" small rounded @click="submitLogin()" dark>Next<v-icon>mdi-arrow-right</v-icon></v-btn>
                                </div>

                            </v-tab-item>
                            <v-tab-item value="tab-3">

                                <div class="col-lg-12 col-12">
                                    <v-text-field class="mt-5" v-model="newPasswordModel" hint="Set your password" :type="showNewPass ? 'text' : 'password'" @click:append="showNewPass = !showNewPass" label="New Password" password dense prepend-icon="mdi-account-key" :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"></v-text-field>
                                </div>
                                <div class="col-lg-12 col-12 text-right">
                                    <v-btn class="mr-3" color="red" elevation="2" small rounded @click="tab = 'tab-1'" dark>Back</v-btn>
                                    <v-btn class="mr-3" color="success" elevation="2" small rounded @click="submitNewPassword()" dark>Confirm New Password</v-btn>
                                </div>

                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                </div>
            </div>
        
        </v-dialog>

        <v-dialog v-model="dialogShowImage" max-width="768px" >
            <div class="card elevation-5">
                <div class="card-body">
                    <!-- <v-img :src = this.imgUrl ></v-img> -->
                    <ul>
                        <li v-for="item in imageList" class="ma-2" style="list-style-type:none;">
                            <!-- {{ item }} -->
                            <v-img :src = item ></v-img>
                        </li>
                    </ul>
                </div>
            </div>
        </v-dialog>

    </div>
</template>

<script>

export default {
    components:{

    },
    data() {
        return {
            show: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            timeout: 7500,
            interval: null,
            time: null, 
            date_now: null,
            intervalData: null,
            headers: [
                { text: 'DATE', value: 'tr_date', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'REASON', value: 'ijin_descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ATTACHMENT', value: 'file_name', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'keterangan', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'START DATE', value: 'dt_from', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'UNTIL DATE', value: 'dt_to', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'stat', align: 'center', class: 'primary--text blue lighten-5' },
                // { text: '', value: 'actions', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            itemLists: [],
            searchItem: '',
            loadingDatatable: false,
            dialogCreateRequest: false,
            addCompanyIDModel: '',
            addCompanyIDList: [],
            addIjinModel: '',
            addIjinList: [],
            addIjinLoading: false,
            dateStartModal: false,
            dateStartModel: '',
            dateStartFormatted: '',
            dateEndModal: false,
            dateEndModel: '',
            dateEndFormatted: '',
            addRemarkModel: '',
            // imageModel: '',
            imageModel: [],
            // isLoggedIn: this.$store.state.absencePermitIsLoggedIn,
            isLoggedIn: false,
            dialogLogin: false,
            usernameModel: '',
            usernameErrorMessages: '',
            passwordModel: '',
            showPass: false,
            tab: 'tab-1',
            newPasswordModel: '',
            showNewPass: false,
            btnNextLabel: 'Next',
            employeeID: '',
            employeeName: '',
            dialogShowImage: false,
            imgUrl: '',
            avatar: 'images/default-user.png',
            doc_id: '',
            fileUpload: [],
            imageExist: null,
            imageList: []
        }
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    created() {
        this.interval = setInterval(() => {
            this.time = Intl.DateTimeFormat('sv-SE', {timeZone: 'Asia/Jakarta', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false}).format()
            this.date_now = Intl.DateTimeFormat('id', {timeZone: 'Asia/Jakarta', day: 'numeric', month: 'long', weekday: "long", year: "numeric"}).format()
        }, 1000)
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){

            if(this.isLoggedIn == false){

                this.dialogLogin = true

            } else {

                this.dateStartModel = this.currentDate()
                this.dateEndModel = this.currentDate()

                await axios.get(`${process.env.VUE_APP_URL}/api/AbsensePermit?id=${ this.employeeID }`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    } 
                })
                .then(res => {

                    this.addCompanyIDModel = res.data.entity[0].entity_id
                    this.addCompanyIDList = res.data.entity
                    this.addIjinList = res.data.ijin

                    this.populateAbsenceList()

                })
                .catch(err => {

                    console.log(err);
                    
                    // if(err.response.status == '401') {
                    //     this.$router.push('/');
                    // } else {
                    //     Swal.fire({
                    //         text: err.response.data.data,
                    //         icon: 'error',
                    //     })
                    // }

                })

            }

        },

        async populateAbsenceList(){

            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true

            await axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/getData`, { 
                id: this.employeeID ? this.employeeID : '',
            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    } 
            })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false

                this.itemLists = res.data.result
                
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async submitAbsenceRequest(){

            if(this.addIjinModel == null || this.addIjinModel == ''){
                Swal.fire({
                        text: 'Please Select Absence Reason !',
                        icon: 'warning',
                    })
                return false
            }

            if(this.addRemarkModel == null || this.addRemarkModel == ''){

                Swal.fire({
                        text: 'Please Fill Absence Remark !',
                        icon: 'warning',
                    })
                return false

            }

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            if(this.fileUpload.length == 0){
                this.imageExist = null
            } else {
                this.imageExist = 'Y'
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/submitData`, { 
                idemployee : this.employeeID ? this.employeeID : '',
                company: this.addCompanyIDModel ? this.addCompanyIDModel : '',
                ijin: this.addIjinModel ? this.addIjinModel : '',
                startDate: this.dateStartFormatted ? this.dateStartFormatted : '',
                endDate: this.dateEndFormatted ? this.dateEndFormatted : '',
                remark: this.addRemarkModel ? this.addRemarkModel : '',
                image: this.imageExist
            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    } 
            })
            .then(res => {
                
                this.uploadFile(res.data.doc_id)
                this.dialogCreateRequest = false
                this.populateAbsenceList()
                
                
            })
            .catch(err => {

                console.log(err);

            })

        },

        showDialogAbsenceRequest(){

            this.dialogCreateRequest = true
            this.addIjinModel = ''
            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()
            this.addRemarkModel = ''
            this.fileUpload = []

        },

        async addCompanyChange(id){

            this.addPositionLoading = true
            this.addPositionList = [];

            await axios.get(`${process.env.VUE_APP_URL}/api/AbsensePermit/getJabatan/?id=${id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                } 
            })
            .then(res => {

                this.addPositionLoading = false
                this.addPositionList = res.data.position

            })
            .catch(err => {
                
                this.addPositionLoading = false
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        showDetails(id){



        },

        uploadFile(id){

            let formData = new FormData()
            formData.append('employee_id', this.employeeID)
            formData.append('doc_id', id)

            for( var i = 0; i < this.fileUpload.length; i++ ){
                let file = this.fileUpload[i];
                formData.append('file[' + i + ']', file)
            }

            axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/uploadFile`, formData, { 
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                } 
            }).then(res => {
                this.fileUpload = []
            }).catch(err => {
                console.log(err)
            })


        },

        async deleteAbsence(id){

            Swal.fire({
                text: 'Do you want Delete this Absence Request ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: 'green',
                denyButtonText: 'Cancel',
                denyButtonColor: 'red'
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/deleteAbsence`, {

                        id: id,

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        this.populateAbsenceList()
                        
                    })
                    .catch(err => {
                        
                        console.log(err);

                    })

                }
            })

        },

        fileChange(e) {
            // let file = e.target.files[0]
            // this.imageModel = []
            // if (!file.type.includes('image/')) {
            //     swal("Error", "Please select an image file!", "error");
            //     return
            // }
            // if (file.size > 10000000) {
            //     swal("Failed", "File image maximum 10 MB!", "error");
            //     return
            // }
            // if (typeof FileReader === 'function') {
            //     const reader = new FileReader()
            //     reader.onload = (event) => {
            //         this.imageModel = event.target.result
            //     }
            //     reader.readAsDataURL(file)
            // } else {
            //     swal("Error", "Sorry, FileReader API not supported", "error");
            // }

        },

        isNumber(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 46 || keyCode > 57 )) { // 46 is dot
                event.preventDefault()
            }
        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        },

        cancelLogin(){

            this.$router.push('/')

        },

        async submitLogin(){

            if(this.passwordModel){

                await axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/submitLogin`, { 
                    username: this.usernameModel ? this.usernameModel : '',
                    password: this.passwordModel ? this.passwordModel : ''
                },
                {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                        } 
                })
                .then(res => {
                    
                    console.log(res.data.result)

                    if(res.data.result == true){

                        if(res.data.avatar){
                            this.avatar = process.env.VUE_APP_URL + '/img/hrd/emp/pas_foto/' + res.data.avatar
                        }
                        
                        this.employeeID = res.data.id
                        this.employeeName = res.data.name
                        this.isLoggedIn = true
                        this.usernameModel = ''
                        this.newPasswordModel = ''
                        this.passwordModel = ''
                        this.dialogLogin = false
                        this.initialize()
                    } else {

                        Swal.fire({
                            text: 'Wrong password !',
                            icon: 'error',
                        })

                        // this.tab = 'tab-1'
                        this.passwordModel = ''
                        console.log(res.data.result);
                        
                    }
                    
                })
                .catch(err => {

                    this.btnNextLabel = 'Next'
                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                Swal.fire({
                    text: 'Please fill password !',
                    icon: 'error',
                })

            }

        },

        async goToNextTab(){

            if(this.usernameModel){

                this.btnNextLabel = 'Loading'

                await axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/checkUser`, { 
                    id: this.usernameModel ? this.usernameModel : ''
                },
                {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                        } 
                })
                .then(res => {
                    
                    this.btnNextLabel = 'Next'
                    console.log(res.data.results)

                    if(res.data.result == 'ok'){
                        this.tab = 'tab-2'
                    } else if(res.data.result == 'new') {
                        this.tab = 'tab-3'
                    } else {
                        this.usernameModel = ''
                        this.usernameErrorMessages = res.data.result
                    }
                    
                })
                .catch(err => {

                    this.btnNextLabel = 'Next'
                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {
                
                Swal.fire({
                    text: 'Please fill username !',
                    icon: 'error',
                })

            }

        },

        async submitNewPassword(){

            if(this.newPasswordModel){

                await axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/setNewPassword`, { 
                    username: this.usernameModel ? this.usernameModel : '',
                    password: this.newPasswordModel ? this.newPasswordModel : ''
                },
                {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                        } 
                })
                .then(res => {
                    
                    console.log(res.data.result)

                    if(res.data.result == true){
                        this.tab = 'tab-1'
                        this.usernameModel = ''
                        this.newPasswordModel = ''

                        Swal.fire({
                            text: 'New password update successfully',
                            icon: 'success',
                        })

                    }
                    
                })
                .catch(err => {

                    this.btnNextLabel = 'Next'
                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                Swal.fire({
                    text: 'Please fill password !',
                    icon: 'error',
                })

            }

        },

        async showImage(id){

            this.$store.dispatch('setOverlay', true)
            this.imageList = []

            await axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/getImages`, { 
                id: id,
            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    } 
            })
            .then(res => {
                
                console.log(res.data);

                // this.imageList = res.data.result

                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    this.imageList.push(process.env.VUE_APP_URL + '/img/hrd/emp/izin/' + element.file_name)
                }

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);

            })

            // this.imgUrl = process.env.VUE_APP_URL + id
            this.dialogShowImage = true

        },

        cancelAbsenceRequest(){

            this.dialogCreateRequest = false
            this.addIjinModel = ''
            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()
            this.addRemarkModel = ''
            this.fileUpload = []

        },

        logout(){
            this.isLoggedIn = false
            this.$router.push('/');
        }

    },
    watch: {

    }
    
}

</script>